@font-face {
  font-family: "MarkPro-Bold SKY";
  src: url('/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro-Book SKY";
  src: url('/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro-Black SKY";
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}